// Generated by Framer (77e91d3)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["v80umS0jD", "ex5Pxm3xH"];

const serializationHash = "framer-e6UbT"

const variantClassNames = {ex5Pxm3xH: "framer-v-oift4n", v80umS0jD: "framer-v-15r3hpv"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {delay: 0, duration: 1, ease: [0.44, 0, 0.56, 1], type: "tween"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Variant 1": "v80umS0jD", "Variant 2": "ex5Pxm3xH"}

const getProps = ({background, height, id, image, width, ...props}) => { return {...props, F0wyKPqHt: image ?? props.F0wyKPqHt, iKI0B3hwy: background ?? props.iKI0B3hwy ?? "rgb(255, 255, 255)", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "v80umS0jD"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;image?: {src: string; srcSet?: string};background?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, F0wyKPqHt, iKI0B3hwy, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "v80umS0jD", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-e6UbT", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><Image {...restProps} background={{alt: "", fit: "fill", sizes: "min(1312px, 100vw)", ...toResponsiveImage(F0wyKPqHt)}} className={cx("framer-15r3hpv", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"v80umS0jD"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({ex5Pxm3xH: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><motion.div className={"framer-3n3hmy"} layoutDependency={layoutDependency} layoutId={"yNyZWhRm2"}><motion.div className={"framer-p7qr6g"} layoutDependency={layoutDependency} layoutId={"mlQx4CRNM"}/><motion.div className={"framer-n9h084"} layoutDependency={layoutDependency} layoutId={"aVEvOj8K6"} style={{backgroundColor: iKI0B3hwy}}/></motion.div></Image></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-e6UbT [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-e6UbT .framer-jy9mz0 { display: block; }", ".framer-e6UbT .framer-15r3hpv { height: 984px; max-width: 1920px; overflow: hidden; position: relative; width: 1312px; }", ".framer-e6UbT .framer-3n3hmy { align-content: center; align-items: center; bottom: 0px; display: flex; flex: none; flex-direction: column; flex-wrap: nowrap; gap: 0px; height: 300%; justify-content: center; left: calc(50.00000000000002% - 100% / 2); overflow: hidden; padding: 0px 0px 0px 0px; position: absolute; width: 100%; }", ".framer-e6UbT .framer-p7qr6g { flex: 1 0 0px; height: 1px; overflow: hidden; position: relative; width: 100%; }", ".framer-e6UbT .framer-n9h084 { flex: 2 0 0px; height: 1px; overflow: visible; position: relative; width: 100%; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-e6UbT .framer-3n3hmy { gap: 0px; } .framer-e6UbT .framer-3n3hmy > * { margin: 0px; margin-bottom: calc(0px / 2); margin-top: calc(0px / 2); } .framer-e6UbT .framer-3n3hmy > :first-child { margin-top: 0px; } .framer-e6UbT .framer-3n3hmy > :last-child { margin-bottom: 0px; } }", ".framer-e6UbT.framer-v-oift4n .framer-15r3hpv { aspect-ratio: 1.3333333333333333 / 1; height: var(--framer-aspect-ratio-supported, 984px); }", ".framer-e6UbT.framer-v-oift4n .framer-3n3hmy { bottom: unset; top: 0px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 984
 * @framerIntrinsicWidth 1312
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"],"constraints":[null,"1920px",null,null]},"ex5Pxm3xH":{"layout":["fixed","fixed"],"constraints":[null,"1920px",null,null]}}}
 * @framerVariables {"F0wyKPqHt":"image","iKI0B3hwy":"background"}
 * @framerImmutableVariables true
 */
const FramerC8Eiu_16p: React.ComponentType<Props> = withCSS(Component, css, "framer-e6UbT") as typeof Component;
export default FramerC8Eiu_16p;

FramerC8Eiu_16p.displayName = "Image Reveal";

FramerC8Eiu_16p.defaultProps = {height: 984, width: 1312};

addPropertyControls(FramerC8Eiu_16p, {variant: {options: ["v80umS0jD", "ex5Pxm3xH"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}, F0wyKPqHt: {title: "Image", type: ControlType.ResponsiveImage}, iKI0B3hwy: {defaultValue: "rgb(255, 255, 255)", title: "Background", type: ControlType.Color}} as any)

addFonts(FramerC8Eiu_16p, [])